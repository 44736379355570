@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;600&display=swap');

* {
  font-family:'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

input[type='checkbox'] {
  /* background-color only for content */
  background-clip: content-box;
  border-radius: 6px;
}

input[type='checkbox']:checked {
  background-color: #ff0000;
}

input[type='checkbox']:focus {
  outline: none !important;
}

